


























































































































.header {
  width: 100%;
  height: 90px;
  background-color: #000;
}
.search {
  //padding-top: 90px;
  width: 1200px;
  margin: 80px auto;
  ::v-deep .el-select .el-input {
    width: 200px;
  }
  ::v-deep .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
}
.list-wrapper {
  margin: 40px auto;
  li {
    height: 80px;
    line-height: 80px;
    border-bottom: 1px dashed #b4b4b4 ;
    a {
      color: #337ab7;
    }
  }
}
.pagination {
  > .container {
    justify-content: center;
  }
}
@media (max-width: 767px){
  .header{
    height: 50px;
  }
  .search{
    width: 100%;
  }
  .list-wrapper {
    li {
      line-height: 25px;
    }
  }
}
